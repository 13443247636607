<template>
  <b-overlay :show="loading" rounded="sm" no-fade>
    <b-row>
      <b-col class="card-container" cols="12">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="addNew" variant="success" block class="mb-2 ">
          Add New Draft
        </b-button>
      </b-col>

      <b-col class="card-container" lg="4" md="6" v-for="(listItem, index) in draftList" :key="index">
        <b-card class="card-app-design">
          <div class="button-container">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="danger" class="right" v-if="!Boolean(listItem.isDefault)" @click="remove($event, listItem.id)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span class="mr-25">Remove</span>
            </b-button>
          </div>

          <div class="card-header">
            <b-card-title id="card-title" class="mt-1">
              {{ listItem.title }}
            </b-card-title>
          </div>

          <div class="card-description">
            <b-card-text class="font-small-2 mb-2"> {{ listItem.description }} &nbsp; </b-card-text>
          </div>

          <!-- design group -->

          <div class="design-group">
            <h6 class="section-label" v-if="listItem.activeUsers">Users</h6>
            <h6 class="section-label" v-if="!listItem.activeUsers">
              User List is Empty
            </h6>

            <b-avatar-group>
              <div v-for="(user, index) in activeAvatars(listItem).slice(0, 5)" :key="index">
                <b-avatar v-if="user" :text="user.name.slice(0, 2)" />
              </div>
              <h6 class="align-self-center cursor-pointer ml-1 mb-0">
                {{ avatarCount(listItem.activeUsers) }}
              </h6>
            </b-avatar-group>
          </div>
          <!--/ design group -->

          <!-- button -->
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="viewDraft(listItem.id)" variant="primary" block>
            View Draft
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BCard, BBadge, BCardTitle, BCardText, BAvatar, BButton, BRow, BCol } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '@/views/settings/settingsStoreModule.js';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
import Ripple from 'vue-ripple-directive';
import router from '@/router';
import axiosIns from '@/libs/axios';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
    BCardActions,
    SettingsCard,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      loading: true,
      draftList: [],
      seiected: [],
      listusers: [],
    };
  },

  methods: {
    avatarCount(actv) {
      if (actv) {
        if (actv.split(',').length > 5) {
          return '+'.concat(String(actv.split(',').length - 5));
        } else {
          return '';
        }
      } else {
        return '';
      }
    },

    activeAvatars(list) {
      const avatarList = [];

      if (list.activeUsers) {
        list.activeUsers.split(',').forEach((element) => {
          avatarList.push(this.listusers.find((x) => x.id === parseInt(element)));
        });
      }
      return avatarList;
    },

    viewDraft(id) {
      this.selected = this.draftList.find((x) => x.id == id);
      router
        .push({
          name: 'settings-inventoryreport-detail',
          params: {
            id: this.selected.id,
          },
        })
        .catch((error) => {
          console.log('error');
        });
    },

    addNew() {
      router.push({ name: 'settings-inventoryreport-create' });
    },

    getItems() {
      const promise1 = store
        .dispatch('settings/fetchHeaders', { type: 'inventoryreport' })
        .then((res) => {
          this.draftList = res.data;
          this.draftList.forEach((element) => {
            if (element.isDefault == 0 || element.isDefault == false || element.isDefault == '0') {
              element.isDefault = 0;
            } else if (element.isDefault == 1 || element.isDefault == true || element.isDefault == '1') {
              element.isDefault = 1;
            }
          });
        })

        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Draft list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
      const promise2 = store
        .dispatch('settings/fetchUsers', [])
        .then((res) => {
          this.listusers = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });

      Promise.all([promise1, promise2]);
    },
    remove(event, id) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          event.preventDefault();
          axiosIns
            .delete(`inventoryreport_headers/${id}`)
            .then((res) => {
              if (res.status == 200) {
                this.getItems();
                this.loading = false;
              }
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },
  },
  created() {
    this.getItems();
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return {
      // Filter
    };
  },
};
</script>

<style>
.card-app-design {
  height: 350px;
  overflow: auto;
  padding-bottom: 25px;
  margin: 10px;
}
.design-group {
  flex: 1;
  padding: 1em;
  border: solid 0px;
  height: 25%;
}

.card-header {
  display: flex;
  text-align: left;
  padding-left: 0em !important;
  margin: 5px;
}

.card-description {
  height: 25%;
  overflow: hidden;
}

.card-title {
  font-size: 1.2rem !important;
  overflow: hidden;
}

.right {
  float: right;
  padding: 10px;
}

.button-container {
  display: solid;
}

#card-title {
  float: left;
}
</style>
